const TEXT = 1;
const TEXTAREA = 2;
const CHECKBOX = 3;
const RADIO = 4;
const SELECT = 5;
const FILE = 6;
const TABLE = 7;
const SPECIAL = 100;

const FIELD_VALUE = {
  [TEXT]: 'TEXT',
  [TEXTAREA]: 'TEXTAREA',
  [CHECKBOX]: 'CHECKBOX',
  [RADIO]: 'RADIO',
  [SELECT]: 'SELECT',
  [FILE]: 'FILE',
  [TABLE]: 'TABLE',
  [SPECIAL]: 'SPECIAL',
};

// bootstrapの表示用のデータ構造
const FIELD_TYPE = {
  TEXT: { value: TEXT, text: 'テキスト' },
  TEXTAREA: { value: TEXTAREA, text: 'テキストエリア' },
  CHECKBOX: { value: CHECKBOX, text: 'チェックボックス' },
  RADIO: { value: RADIO, text: 'ラジオボタン' },
  SELECT: { value: SELECT, text: 'セレクトボックス' },
  FILE: { value: FILE, text: 'ファイル' },
  TABLE: { value: TABLE, text: 'テーブル' },
  SPECIAL: { value: SPECIAL, text: '特殊' },
};

const FIELD_TEMPLATES = {
  [TEXT]: [
    { value: 1, text: '小' },
    { value: 2, text: '中' },
    { value: 3, text: '大' },
  ],
  [TEXTAREA]: {
    1: '3行・小',
    2: '5行・中',
    3: '7行・大',
  },
  [CHECKBOX]: {
    1: '横並び',
    2: '縦並び',
  },
  [RADIO]: {
    1: '横並び',
    2: '縦並び',
  },
  [SELECT]: {
    1: '小',
    2: '中',
    3: '大',
  },
  [FILE]: {
    1: 'デフォルト',
  },
  [TABLE]: {
    1: 'デフォルト',
  },
  [SPECIAL]: {
    1: '資金計画',
  },
};

const TABLE_COLUMN_TEMPLATES = {
  1: '小',
  2: '中',
  3: '大',
};

const TABLE_INPUT_TYEPS = {
  TEXT: 1,
  SELECT: 2,
  FIXED: 3,
  NUMBER: 4,
};


const TABLE_INPUT_TYEPS_OPTION = [
  { value: TABLE_INPUT_TYEPS.TEXT, text: 'テキスト入力' },
  { value: TABLE_INPUT_TYEPS.NUMBER, text: 'テキスト入力（数値）' },
  { value: TABLE_INPUT_TYEPS.SELECT, text: '選択方式' },
  { value: TABLE_INPUT_TYEPS.FIXED, text: '固定テキスト表示' },
];

const DIVISIONS = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
];

const GENDER_TYPE_OPTION = [
  { value: null, text: '---' },
  { value: 1, text: '男性' },
  { value: 2, text: '女性' },
  { value: 3, text: 'その他' },
  { value: 4, text: '回答しない' },
];
const GENDER_TEXT = {
  1: '男性',
  2: '女性',
  3: 'その他',
  4: '回答しない',
};
export default {
  FIELD_TYPE,
  FIELD_VALUE,
  FIELD_TEMPLATES,
  TABLE_COLUMN_TEMPLATES,
  TABLE_INPUT_TYEPS,
  TABLE_INPUT_TYEPS_OPTION,
  DIVISIONS,
  GENDER_TYPE_OPTION,
  GENDER_TEXT,
};
